import CreateMultipleChoiceQuestion from "../competitions/CreateMultipleChoiceQuestion";
import CreateShortAnswerQuestion from "../competitions/CreateShortAnswerQuestion";

const SimpleQuestionView = ({question})=>{
    if (question?.choices) {
        return <CreateMultipleChoiceQuestion initialQuestion={question} />
    }

    return <CreateShortAnswerQuestion initialQuestion={question} />
}

export default SimpleQuestionView;