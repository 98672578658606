import { useContext, useEffect } from 'react';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { PageContext } from '../context/PageContextProvider';
import CreateShortAnswerQuestion from '../components/competitions/CreateShortAnswerQuestion';
import { AuthContext } from '../context/AuthContextProvider';
import { ADMIN_ROLE } from '../utils/roleComputation';
import { createQuestion } from '../services/competitions';
import { ALERT_ERROR_MESSAGE } from '../utils/ResponseUtils';
import useWithLoading from '../hooks/useWithLoading';
import Loading from '../components/common/Loading';
import { NavigatableTabs } from '../components/dashboard/DashboardComponents';
import CreateMultipleChoiceQuestion from '../components/competitions/CreateMultipleChoiceQuestion';

const pageMetadata = {
  titleLH: 'Question',
  titleRH: 'Creator',
  body: 'Make a Question!',
};

function CreateQuestionPage() {
  const { setPageMetadata } = useContext(PageContext);
  const { examCode } = useParams();
  const [loading, withLoading] = useWithLoading();

  useEffect(() => {
    pageMetadata.body = (examCode === undefined
      ? 'Create a Question!'
      : `For Exam Code: ${examCode}`
    );
    setPageMetadata(pageMetadata);
  }, []);

  const { authRetrievedProfile } = useContext(AuthContext);
  const history = useHistory();

  useEffect(() => {
    // We do a security check to ensure that only an admin account is on this page.
    if (!authRetrievedProfile || authRetrievedProfile.role !== ADMIN_ROLE) {
      history.push('/');
    }
  }, []);

  const submitQuestion = (question, imageFile) => {
    withLoading(
      createQuestion(authRetrievedProfile, question, examCode, imageFile),
      () => {
        toast.success('Question Created!');
      },
      ALERT_ERROR_MESSAGE,
    );
  };

  const tabs = {
    'Multichoice Question': <CreateMultipleChoiceQuestion createQuestion={submitQuestion} examCode={examCode} />,
    'Short Answer Question': <CreateShortAnswerQuestion createQuestion={submitQuestion} examCode={examCode} />,
  };

  return (
    <Loading loading={loading}>
      <NavigatableTabs tabs={tabs} />
    </Loading>
  );
}

export default CreateQuestionPage;
