import {useEffect, useState} from 'react';
import RichTextDisplay from '../richtext/RichTextDisplay';
import styles from './FocusInput.module.css';
import RichTextInputV2 from './RichTextInputV2';
import TextInputV3 from './TextInputV3';

/** A wrapper component around richtext to make it only be editable when focused */
export default function FocusInput({ outerClassName, rich = false, required, ...rest }) {
  const [focused, setFocused] = useState(false);

  return (
    rich ? (
      <div
        className={`${styles.focusdiv} ${outerClassName}`}
        onFocus={() => setFocused(true)}
        onBlur={() => setFocused(false)}
      >
        {focused ? <RichTextInputV2 {...rest} /> : <RichTextDisplay focusable {...rest} />}
      </div>
    ) : <TextInputV3 required={required} {...rest} outerClassName={outerClassName} />
  );
}
