import { Dropdown as BootstrapDropdown, DropdownButton } from 'react-bootstrap';

export default function Dropdown({ label, options, handleSelect }) {
  return (
    <DropdownButton
      title={label}
      variant="secondary"
    >
      {options.map((option, i) => (
        <BootstrapDropdown.Item
          key={option}
          onClick={(e) => handleSelect(i, e)}
        >
          {option}
        </BootstrapDropdown.Item>
      ))}
    </DropdownButton>
  );
}
