import { useState } from 'react';
import { Card, Stack } from 'react-bootstrap';
import FocusInput from '../forms/common/stateless/FocusInput';
import styles from './CompactQuestion.module.css';
import { QuestionPreview } from './QuestionPreview';


export default function CompactQuestion({
  question, onDrop, index, changeQuestion,
}) {
  const [viewed, setViewed] = useState(false);
  const [covered, setCovered] = useState(false);

  const handleDragStart = (e) => {
    e.dataTransfer.setData('draggedQuestion', question.id);
    setViewed(false);
  };

  const handleOnDragOver = (e) => {
    e.preventDefault();
    e.dataTransfer.dropEffect = 'move';
    setCovered(true);
    setViewed(false);
  };

  const handleOnDragLeave = () => {
    setCovered(false);
  };

  const handleTitleChange = (event) => {
    const title = event.target.value;
    changeQuestion({ ...question, title });
  };

  return (
    <Card
      className={`mx-auto rounded ${covered ? 'border-primary' : ''}`}
      draggable
      onDragStart={(e) => handleDragStart(e)}
      onDragOver={(e) => handleOnDragOver(e)}
      onDragLeave={() => handleOnDragLeave()}
      onDrop={(e) => onDrop(e, index, () => setCovered(false))}
    >
      <Card.Body
        className={styles.clickable}
        onClick={(e) => setViewed(!viewed)}
      >
        <Stack direction="horizontal" gap={3}>
          <Card className="border-0 w-100">
            <Stack direction="horizontal" gap={3}>
              <i className={viewed ? 'bi bi-chevron-down' : 'bi bi-chevron-right'} />
              <FocusInput
                value={question.title}
                onChange={handleTitleChange}
                onClick={(e) => e.stopPropagation()}
                outerClassName="w-100"
                className={styles.title}
              />
            </Stack>
          </Card>
          <div className={styles.draggable}>
            <i className="bi bi-list" />
          </div>
        </Stack>
      </Card.Body>
      {viewed && (
        <QuestionPreview question={question} changeQuestion={changeQuestion} />
      )}
    </Card>
  );
}
