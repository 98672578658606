import { useState } from 'react';
import { Pagination } from 'react-bootstrap';


export default function FormattedPagination({
  totalPages, handleObtainPage, pageBuffer = 5, jumpToEnds = false, goToSide = false, ...rest
}) {
  const [active, setActive] = useState(1);

  const handleSelect = (page) => {
    setActive(page);
    handleObtainPage(page);
  };

  return (
    <Pagination {...rest}>
      {jumpToEnds && <Pagination.First onClick={() => handleSelect(1)} disabled={active <= 1} />}
      {goToSide && <Pagination.Prev onClick={() => handleSelect(active - 1)} disabled={active <= 1} />}
      {pageItems(totalPages, active, handleSelect, pageBuffer)}
      {goToSide && <Pagination.Next onClick={() => handleSelect(active + 1)} disabled={active >= totalPages} />}
      {jumpToEnds && <Pagination.Last onClick={() => handleSelect(totalPages)} disabled={active <= totalPages} />}
    </Pagination>

  );
}

const pageItems = (pages, active, handleSelect, pageBuffer) => {
  const items = [];
  let [start, end] = [active - pageBuffer, active + pageBuffer];
  if (start < 1) end += Math.abs(start) + 1;
  if (end > pages) start -= end - pages;
  if (start < 1) start = 1;
  if (end > pages) end = pages;

  if (active) {
    for (let number = start; number <= end; number++) {
      items.push(
        <Pagination.Item key={number} active={number === active} onClick={() => handleSelect(number)}>
          {number}
        </Pagination.Item>,
      );
    }
  }
  return items;
};
