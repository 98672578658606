import { EditorContent, useEditor } from '@tiptap/react';
import 'katex/dist/katex.min.css';
import React, { useEffect } from 'react';
import { RICH_TEXT_EXTENSIONS } from './config';
import styles from './RichTextInput.module.css';

function RichTextDisplay({ content, focusable = false, ...rest }) {
  const editor = useEditor({
    editable: focusable,
    content,
    extensions: [...RICH_TEXT_EXTENSIONS],
  });

  useEffect(() => {
    if (editor) {
      editor.commands.setContent(content);
    }
  }, [editor]); // Should only run once on second render

  return (
    <div {...rest} className={`${styles.editor} p-3 ${rest.className}`}>
      {editor && <EditorContent editor={editor} />}
    </div>
  );
}

export default RichTextDisplay;
