import axios from 'axios';

export const createExamForEvent = (auth, eventCode, exam) => async () => await axios.post(`/exams/${eventCode}?email=${auth.email}&code=${auth.code}`, exam);
export const getJoinedExams = (auth) => async () => await axios.get(`/exams/joined/?email=${auth.email}&code=${auth.code}`);
export const getAllExams = (auth) => async () => await axios.get(`/exams?email=${auth?.email}&code=${auth?.code}`);
export const getExamForStudent = (auth, eventCode) => async () => await axios.get(`/exams/${eventCode}?email=${auth?.email}&code=${auth?.code}`);
export const getExamForAdmin = (auth, examCode) => async () => await axios.get(`/exams/admin/${examCode}?email=${auth.email}&code=${auth.code}`);
export const updateExamQuestionOrder = (auth, examCode, questions) => async () => await axios.put(`/exams/update/${examCode}?email=${auth.email}&code=${auth.code}`, questions);
export const deleteExam = (auth, examId) => async () => await axios.delete(`/exams/delete/${examId}?email=${auth.email}&code=${auth.code}`);

export const submitAttempt = (auth, submission) => async () => await axios.post(`/examSubmissions?email=${auth.email}&code=${auth.code}&examId=${submission.examId}`, submission);

export const getAllQuestions = (auth, page, query) => async () => await axios.get(`/questions?email=${auth.email}&code=${auth.code}&page=${page - 1}${query}`);

export const getResultsForStudent = (auth, eventCode, studentEmail) => async () => await axios.get(`/examSubmissions/${eventCode}/result/${studentEmail}?email=${auth.email}&code=${auth.code}`);

