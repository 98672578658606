import { EditorContent, useEditor } from '@tiptap/react';
import 'katex/dist/katex.min.css';
import {useEffect, useState} from 'react';
import styles from '../richtext/RichTextInput.module.css';
import RichTextMenu from '../richtext/RichTextMenu';
import { RICH_TEXT_EXTENSIONS } from '../richtext/config';
import PrettyFormGroupV2 from './PrettyFormGroupV2';

/**
 * Used for rich text input
 */
export default function RichTextInputV2({
  label, content, onValueChange, required, ...rest
}) {

  const [focused, setFocused] = useState(false);

  const editor = useEditor({

    onUpdate({ editor }) {
      onValueChange?.(editor.getHTML());
    },
    content,
    extensions: [...RICH_TEXT_EXTENSIONS],
  });

  useEffect(() => {
    if (!editor) return;
    // const cursorLocation = editor.state.selection.$anchor.pos;
    editor.commands.setContent(content);
    // editor.commands.focus(cursorLocation);
  }, [editor, content]);

  if (!editor) return null;


  return (
    <PrettyFormGroupV2 className={""} label={label} required={required}>
      <div {...rest} className={`${styles.focusdiv} ${styles.editor} ${rest.className} rounded-0`} style={{width: "99%"}}>
        <div>
          {focused && <RichTextMenu editor={editor} />}
          <div className="p-3">
            <EditorContent onFocus={() => setFocused(true)}
                           onBlur={() => setFocused(false)}
                           editor={editor} contentEditable="false" />
          </div>
        </div>
      </div>
    </PrettyFormGroupV2>
  );
}
