import { useContext, useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import useWithLoading from '../../hooks/useWithLoading';
import { AuthContext } from '../../context/AuthContextProvider';
import { getJoinedExams } from '../../services/exams';
import { ALERT_ERROR_MESSAGE } from '../../utils/ResponseUtils';
import Loading from '../common/Loading';
import ExamCard from './ExamCard';
import { ADMIN_ROLE, STUDENT_ROLE } from '../../utils/roleComputation';

function ExamList() {
  const [exams, setExams] = useState();
  const [loading, withLoading] = useWithLoading();
  const { authRetrievedProfile } = useContext(AuthContext);

  const isStudent = authRetrievedProfile?.role === STUDENT_ROLE;

  let noExamsOutput;
  if (!authRetrievedProfile) {
    noExamsOutput = <p>Only available when signed in</p>;
  } else if (isStudent) {
    noExamsOutput = <p>No exams available</p>;
  } else if (authRetrievedProfile?.role === ADMIN_ROLE) {
    noExamsOutput = <p>Exams will be displayed here for students</p>;
  }

  useEffect(() => {
    if (!isStudent) {
      return;
    }
    withLoading(
      getJoinedExams(authRetrievedProfile),
      (res) => setExams(res?.data),
      ALERT_ERROR_MESSAGE,
    );
  }, [isStudent]);

  return (
    <Loading loading={loading} message="Retrieving exams">
      <Row className="mb-3">
        <p className="fw-bold text-muted">Exams</p>
        {(!exams || Object.keys(exams).length === 0)
          ? <p className="text-muted">{noExamsOutput}</p>
          : Object.entries(exams).map(([eventCode, exam]) => (
            <Col xs={12} lg={6} key={eventCode}>
              <ExamCard eventCode={eventCode} exam={exam} />
            </Col>
          ))}
      </Row>
    </Loading>
  );
}

export default ExamList;
