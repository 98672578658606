import { useContext } from 'react';
import {
  Col, Container, Nav, Navbar, Row,
} from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import LogIn from './components/common/login/LogIn';
import LogOut from './components/common/login/LogOut';
import { AuthContext } from './context/AuthContextProvider';
import { PageContext } from './context/PageContextProvider';
import Favicon from './favicon.png';

export function Footer() {
  return (
    <footer className="text-center text-lg-start bg-dark text-white mt-5">
      <section className="p-3">
        <div className="container text-center">
          <Row>
            <Col xs={12} lg={6}>
              General, Contact: contact@nzpmc.com
              <br />
              Business, Partnership: business@nzpmc.com
            </Col>
            <Col xs={12} lg={6}>
              <a className="text-reset" href="https://www.nzpmc.com/about-us/sponsors-supporters">Supporters</a>
              <br />
              <a className="text-reset" href="https://www.nzpmc.com/resources/past-questions">
                Past
                Questions
              </a>
            </Col>
          </Row>
        </div>
      </section>

      <div className="text-center text-white-50 p-4 bg-black">
        ©
        {' '}
        {new Date().getFullYear()}
        {' '}
        Copyright:
        {' '}
        {' '}
        <a className="text-reset fw-bold" href="https://www.nzpmc.com">The NZPMC</a>
        <br />
        <span>Since 2018 - JM and HK.</span>
      </div>

    </footer>

  );
}

export function Navigation() {
  const history = useHistory();
  const { authRetrievedProfile } = useContext(AuthContext);

  return (
    <Navbar bg="dark" variant="dark" expand="lg">
      <Container>
        <Navbar.Brand style={{ cursor: 'pointer' }} onClick={() => history.push('/')}>
          <img alt="" src={Favicon} width={40} height={40} style={{ marginRight: '10px' }} />
          The NZPMC
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto">
            <Nav.Link href="#home" onClick={() => history.push('/')}>Home</Nav.Link>
            <Nav.Link onClick={() => history.push('/announcements')}>Announcements</Nav.Link>
            <Nav.Link onClick={() => history.push('/events')}>Events</Nav.Link>
            <Nav.Link onClick={() => history.push('/releaseNotes')}>Release Notes</Nav.Link>
            <Nav.Link onClick={() => window.open('https://www.nzpmc.com/', '_blank')}>
              Official Website
              {' '}
              <i
                className="bi bi-box-arrow-up-right"
              />
            </Nav.Link>
          </Nav>
          <div className="lead text-white">{!authRetrievedProfile ? <LogIn /> : <LogOut />}</div>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export function Instruction() {
  const { pageMetadata } = useContext(PageContext);

  return (
    <div>
      {pageMetadata && (
        <section
          className="bg-dark text-light p-5 text-center text-sm-start"
        >
          <div className="container">
            <div className="d-sm-flex align-items-center justify-content-between">
              <div>
                <h1>
                  {pageMetadata.titleLH}
                  {pageMetadata.titleLH && ' '}
                  <span
                    className="text-warning"
                  >
                    {pageMetadata.titleRH}
                  </span>
                </h1>
                <p className="lead d-none d-sm-block">{pageMetadata.body}</p>
              </div>
            </div>
          </div>
        </section>
      )}
    </div>
  );
}
