import { Button, Col } from 'react-bootstrap';
import { useContext, useEffect, useState } from 'react';
import { EventContext } from '../../../../pages/EventPage';
import { AuthContext } from '../../../../context/AuthContextProvider';
import { getRole, STUDENT_ROLE } from '../../../../utils/roleComputation';
import useSimpleModal from '../../../../hooks/useSimpleModal';
import { getResultsForStudent } from '../../../../services/exams';
import { ALERT_ERROR_MESSAGE } from '../../../../utils/ResponseUtils';
import useWithLoading from '../../../../hooks/useWithLoading';

function ResultsCard({ className }) {
  const { authRetrievedProfile: auth } = useContext(AuthContext);
  const [setModalVisible, ModalWrapper] = useSimpleModal();
  const { eventCode, userTicket } = useContext(EventContext);
  const [newResult, setNewResult] = useState(null);
  const { result } = userTicket || {};
  const [loading, withLoading] = useWithLoading();

  if (getRole(auth) !== STUDENT_ROLE) return null;

  useEffect(() => {
    withLoading(
      getResultsForStudent(auth, eventCode, auth.email),
      (res) => {
        setNewResult(res?.data);
      },
      // wrong implementation rn anyway so silence the error. you should fetch EventResult
      ()=>{}
    );
  }, []);

  return (
    <>
      <ModalWrapper title="Performance Summary">
        {
          newResult ? (
            <ResultPopup result={newResult} />
          ) : result ? (
            <ResultPopup result={result} />
          ) : (
            <p>No results found</p>
          )
        }
      </ModalWrapper>
      {(result || newResult) && (
      <a className={className} onClick={setModalVisible}>
        View Results
      </a>
      )}
    </>
  );
}


function ResultPopup({ result }) {
  const { userTicket, eventName, eventCode } = useContext(EventContext);
  const { personSnapshot } = userTicket || {};
  const { island, yearLevel } = personSnapshot || {};

  if (!result) return null;

  const division = yearLevel <= 11 ? 'Junior' : 'Senior';
  const titles = `(${result.titles.join(', ')})`;
  const links = result.attachmentURLs.map((pdfUrl) => (
    <li>
      <b>Certificate:</b>
      {' '}
      <a href={pdfUrl} target="_blank" rel="noreferrer"><i className="bi bi-box-arrow-up-right" /></a>
    </li>
  ));

  return (
    <Col xs={12} lg={12} className="mb-3">
      <ul>
        <li>
          <b>Competition Name:</b>
          {' '}
          {eventName}
        </li>
        <li>
          <b>Competition Code:</b>
          {' '}
          {eventCode}
        </li>
        <li>
          <b>Score:</b>
          {' '}
          {result.score || result.mark}
        </li>
        <li>
          <b>Titles:</b>
          {' '}
          {titles}
        </li>
        <li>
          <b>Division:</b>
          {' '}
          {division}
        </li>
        <li>
          <b>Island:</b>
          {' '}
          {island}
        </li>
        {links}
      </ul>
    </Col>
  );
}

export default ResultsCard;
