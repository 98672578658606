import Form from 'react-bootstrap/Form';
// eslint-disable-next-line import/no-cycle
import { PrettyFormGroup } from '../FormComponents';

export default function TextInputV2({label, value, onValueChange, required = false, enforceNumeric = false}){

  return (
    <PrettyFormGroup label={label} required={required}>
      <Form.Control
        value={value}
        onChange={(e)=>onValueChange(e.target.value)}
        type={enforceNumeric ? 'number' : 'text'}
        required={required}
      />
    </PrettyFormGroup>
  );
};
