import { useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { PrettyForm } from '../forms/common/PrettyForm';
import { AuthContext } from '../../context/AuthContextProvider';
import MultiChoiceBuilderV2 from '../forms/exam/stateless/MultiChoiceBuilderV2';
import { toast } from 'react-toastify';
import RichTextInputV2 from '../forms/common/stateless/RichTextInputV2';
import TextInputV2 from '../forms/common/stateless/TextInputV2';
import SelectInputV2 from '../forms/common/stateless/SelectInputV2';
import FileInputV2 from '../forms/common/stateless/FileInputV2';
import { DIFFICULTY_TAG, DIVISION_TAG, TOPIC_TAG } from './QuestionAttributeConstants';
import FocusInput from "../forms/common/stateless/FocusInput";
import PreviewMultiChoice from "../examPreviewPage/QuestionTypes/PreviewMultiChoice";

function CreateMultipleChoiceQuestion({ createQuestion, examCode, initialQuestion, choiceArraySize=4 }) {
  const { authRetrievedProfile } = useContext(AuthContext);

  const previousChoicesIsGiven = initialQuestion?.choices !== undefined && initialQuestion?.correctAns !== undefined;

  const initialChoices = previousChoicesIsGiven
    ? Object.entries(initialQuestion?.choices).map(([key, value]) => value)
    : Array(choiceArraySize).fill('');
  const initialCorrectIndex = previousChoicesIsGiven
    ? Object.entries(initialQuestion?.choices).findIndex(([key, value]) => key === initialQuestion?.correctAns)
    : '';

  const [question, setQuestion] = useState({
    ...initialQuestion,
    questionOwner: authRetrievedProfile.name,
    type: "MultipleChoice",
    rawChoiceArray: initialChoices,
    rawCorrectIndex: initialCorrectIndex,
    usedFor: examCode === undefined ? [] : [examCode],
  });


  const [imageFile, setImageFile] = useState(null);

  const history = useHistory();

  const onChoiceArrayChange = (choiceIndex, newChoice) => {
    checkIndexOutOfBound(question.rawChoiceArray, choiceIndex)

    setQuestion({
      ...question,
      rawChoiceArray: question.rawChoiceArray.map((choice, index) => index === choiceIndex ? newChoice : choice)
    });
  }

  const onCorrectChoiceIndexChange = (correctChoiceIndex) => {
    checkIndexOutOfBound(question.rawChoiceArray, correctChoiceIndex)

    setQuestion({
      ...question,
      rawCorrectIndex: correctChoiceIndex
    });
  }

  const checkIndexOutOfBound = (array, index) => {
    if (array.length <= index) {
      toast.error(`Index out of bounds ${array.length}, ${index}`);
      return false;
    }
    return true;
  }

  return (
    <PrettyForm
      submitText="Create Question"
      cancelText="Cancel"
      onCancel={() => history.goBack()}
      onSubmit={() => createQuestion(question, imageFile)}
    >
      <TextInputV2 label="Title" value={initialQuestion?.title} onValueChange={title => setQuestion({ ...question, title })} required />
      <RichTextInputV2 label="Body" content={initialQuestion?.body} onValueChange={body => setQuestion({ ...question, body })} focusable required />
      <FileInputV2 label="Image (Drag and Drop)" value={initialQuestion?.imageUrl} onValueChange={image => setImageFile(image)} />
      <SelectInputV2
        label={"Topic"}
        value={initialQuestion?.topic}
        onValueChange={topic => setQuestion({ ...question, topic })}
        options={TOPIC_TAG}
        required
      />
      <SelectInputV2
        label={"Difficulty"}
        value={initialQuestion?.difficulty}
        onValueChange={difficulty => setQuestion({ ...question, difficulty })}
        options={DIFFICULTY_TAG}
        required
      />
      <SelectInputV2
        label={"Division"}
        value={initialQuestion?.division}
        onValueChange={division => setQuestion({ ...question, division })}
        options={DIVISION_TAG}
        required
      />
      <MultiChoiceBuilderV2 label="Multichoice (Select Correct Answer)"
                            choices={initialChoices}
                            correctAnswerIndex={initialCorrectIndex}
                            onChoiceArrayChange={onChoiceArrayChange} onCorrectChoiceIndexChange={onCorrectChoiceIndexChange} choiceArraySize={choiceArraySize} />
      {/*<em>{`Question Owner: ${question.questionOwner}`}</em>*/}
    </PrettyForm>
  );
}


export default CreateMultipleChoiceQuestion;
