import { PrettyFormGroup } from '../FormComponents'

export default function SelectInputV2({
  label, value, options, onValueChange, required = false,
}) {

  return (
    <PrettyFormGroup className="my-3" label={label} required={required}>
      <select
        onChange={(e)=>{
          onValueChange(e.target.value)
        }}
        className="form-select"
        aria-label="select example"
        required={required}
        value={value}
      >
        <option value="">Select...</option>
        {options?.map((e) => <option key={e} value={e}>{e}</option>)}
      </select>
    </PrettyFormGroup>
  );
}
