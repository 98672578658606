import { Stack } from 'react-bootstrap';
import { ExamDropdowns, ExamTag } from '../common/exam/ExamDropdownTagList';

export default function QuestionTagEditor({ question, changeQuestion }) {
  // The current value of each tag in the question
  const currentTags = Object.keys(examTagOptions).map((key) => ({ tag: key, value: question[key] }));

  const changeTag = (key, option) => {
    changeQuestion({ ...question, [key]: option });
  };

  return (
    <Stack direction="vertical" gap={2}>
      <Stack direction="horizontal" className="justify-content-center">
        <ExamDropdowns examTagOptions={examTagOptions} handleSelect={changeTag} />
      </Stack>
      <Stack direction="horizontal" className="justify-content-center" gap={1}>
        {currentTags.map(({ tag, value }) => (
          <ExamTag key={tag} label={tag} option={value} />
        ))}
      </Stack>
    </Stack>
  );
}
