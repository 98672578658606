import { Badge, Stack } from 'react-bootstrap';
import Dropdown from '../Dropdown';

// The row of dropdowns allowing users to select tags
export function ExamDropdowns({ examTagOptions, handleSelect }) {
  return (
    <Stack direction="horizontal" gap={3} style={{ flexWrap: 'wrap' }}>
      {Object.entries(examTagOptions).map(([key, tag]) => {
        const { label, options } = tag;
        return (
          <Dropdown
            key={key}
            label={label}
            options={options}
            handleSelect={(index) => handleSelect(key, options[index])}
          />
        );
      })}
    </Stack>
  );
}

// The row of badges showing the tags selected
export function ExamTags({ selectedTags, handleRemove }) {
  return (
    <div>
      <br />
      <b>
        Selected:
        {' '}
      </b>
      {Object.entries(selectedTags).map(([label, selected]) => (
        selected.map((option) => (
          <ExamTag
            key={`badge-${label}-value-${option}`}
            label={label}
            option={option}
            handleRemove={handleRemove}
          />
        ))
      ))}
    </div>
  );
}

export function ExamTag({ label, option, handleRemove }) {
  return (
    <Badge
      key={`badge-${label}-value-${option}`}
      bg="secondary"
      className="me-1"
      style={{ cursor: handleRemove && 'pointer' }}
      onClick={() => handleRemove?.(label, option)}
    >
      {`${option} `}
      {handleRemove && <i className="bi bi-x" />}
    </Badge>
  );
}
