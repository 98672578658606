import Form from 'react-bootstrap/Form';
import PrettyFormGroupV2 from './PrettyFormGroupV2';

export default function TextInputV3({
  label, required = false, enforceNumeric = false, outerClassName = '', ...rest
}) {
  return (
    <PrettyFormGroupV2 label={label} required={required} className={outerClassName}>
      <Form.Control
        {...rest}
        type={enforceNumeric ? 'number' : 'text'}
        required={required}
      />
    </PrettyFormGroupV2>
  );
}
