import { useContext, useEffect, useState } from 'react';
import {Breadcrumb, Button, Card, Col, Row, Stack} from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { QuestionFilter } from '../components/browsequestionspage/QuestionFilter';
import QuestionTabler from '../components/browsequestionspage/QuestionsTabler';
import FormattedPagination from '../components/common/FormattedPagination';
import { AuthContext } from '../context/AuthContextProvider';
import { PageContext } from '../context/PageContextProvider';
import { getAllQuestions } from '../services/exams';
import SimpleQuestionView from "../components/browsequestionspage/SimpleQuestionView";
import SelectInputV2 from "../components/forms/common/stateless/SelectInputV2";
import CreateMultipleChoiceQuestion from "../components/competitions/CreateMultipleChoiceQuestion";
import CreateShortAnswerQuestion from "../components/competitions/CreateShortAnswerQuestion";

// { label: selected[], ... }      where selected: [] means everything is selected

export default function BrowseQuestionsPage() {
  const { setPageMetadata } = useContext(PageContext);
  const { authRetrievedProfile } = useContext(AuthContext);
  const [questions, setQuestions] = useState([]); // All questions

  const [selectedTags, setSelectedTags] = useState({});

  const [totalPage, setTotalPage] = useState(1); // Total pages of questions
  const [selectedQuestionId, setSelectedQuestionId] = useState(null);

  const history = useHistory();

  useEffect(() => {
    setPageMetadata();
  }, []);

  useEffect(() => {
    if (authRetrievedProfile.role !== 'Admin') {
      history.goBack();
    } else {
      // Get all questions when the page loads
      getAllQuestions(authRetrievedProfile, 1, '')()
        .then((res) => {
          setTotalPage(res.data.totalPages);
          setQuestions(res.data.content);
        })
        .catch((err) => {
          console.error('Failed to fetch questions');
        });
    }
  }, [authRetrievedProfile]);

  const handleObtainPage = (page) => {
    let query = '';
    Object.entries(selectedTags).forEach(([key, value]) => {
      query += `&${key}=${value}`;
    });

    getAllQuestions(authRetrievedProfile, page, query)()
      .then((res) => {
        console.log(res);
        setTotalPage(res.data.totalPages);
        setQuestions(res.data.content);
      })
      .catch((err) => {
        console.error('Failed to fetch questions');
      });
  };

  return (
    <>
      <Breadcrumb>
        <Breadcrumb.Item onClick={() => history.push('/')}>Home</Breadcrumb.Item>
        <Breadcrumb.Item active>Question Browser</Breadcrumb.Item>
      </Breadcrumb>
      <div>
        <Row>
          <Col xs={9}>
            <Card className={"ps-3"}>
              <div className="d-flex">
                <Stack direction="horizontal" gap={3}>
                  {EXAM_TAG_OPTIONS.map(category => {
                    return <SelectInputV2 label={category.label}
                                          options={category.options}
                                          onValueChange={value => setSelectedTags({
                                            ...selectedTags,
                                            [category.queryParam]: value
                                          })} />
                  })}
                  <Button>
                    <i class="bi bi-x-lg"></i> Reset
                  </Button>
                  <Button variant={"success"} onClick={()=>handleObtainPage(0)}>
                    <i class="bi bi-search"></i> Search
                  </Button>
                </Stack>
              </div>
            </Card>
          </Col>
          <Col xs={3}>
            <Button variant="primary" className="mt-3 w-100" onClick={() => history.push('/admin/createQuestion')}>
              <i className="bi bi-plus" />
              {' '}
              Create New Question
            </Button>
          </Col>
        </Row>
        <br />
        <FormattedPagination
          totalPages={totalPage}
          handleObtainPage={handleObtainPage}
          goToSide
          jumpToEnds
          className="justify-content-center"
        />
        <Row>
          <Col xs={7}>
            <div id={"questionTable"}>
              <QuestionTabler questions={questions} selectedQuestionId={selectedQuestionId} onSelectedQuestionChange={id => setSelectedQuestionId(id)} />
            </div>
          </Col>
          <Col xs={5} id={"questionPreview"} className={"border p-4"} style={{ overflow: "scroll", height: "90vh"}} >
            <div>
              {selectedQuestionId
                  ? <SimpleQuestionView question={questions.find(question => question.id === selectedQuestionId)} />
                  : <div className="text-center text-muted">
                    Please select question from the left
                  </div>}
            </div>
          </Col>
        </Row>
      </div>
    </>
  );
}

const EXAM_TAG_OPTIONS = [
  {
    label: 'Difficulty',
    queryParam: 'difficulty',
    options: ['Easy', 'Medium', 'Hard', 'ExtraHard'],
  },
  {
    label: 'Topic',
    queryParam: 'topic',
    options: ['Mechanics', 'Quantum', 'Waves', 'EleMag', 'Algebra', 'Geometry', 'Calc', 'Stats'],
  },
  {
    label: 'Division',
    queryParam: 'division',
    options: ['Junior', 'Senior'],
  },
];