import { toast } from 'react-toastify';

export const ALERT_ERROR_MESSAGE = (e) => {
  console.error(e)
  toast.error(e?.response?.data);
};

export const ALERT_RES_DATA = (res) => {
  toast.info(res?.data);
};
