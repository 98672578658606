import './EventActionGroup.css';
import AddFormButton from './admin/AddFormButton';
import AttachExamButton from './admin/AttachExamButton';
import CreateAnnouncementButton from './admin/CreateAnnouncementButton';
import ReleaseEventButton from './admin/ReleaseEvent';
import ViewFormButton from './admin/ViewFormButton';
import EventPrimaryActionButton from './student/EventPrimaryActionButton';
import ResultsCard from './student/ResultsCard';
import TeacherActionButton from './teacher/TeacherActionButton';

function EventActionGroup() {
  const common = 'd-block fw-bold text-decoration-none lead';
  const studentClassName = `${common}`;
  const teacherClassName = `bi bi-people ${common}`;
  const adminClassName = `bi bi-person-gear ${common}`;

  return (
    <>
      <small className="fw-bold text-muted small">Actions</small>
      <div className="event-actions-group">
        <div className="student-actions">
          <EventPrimaryActionButton />
          <ResultsCard className={studentClassName} />
        </div>
        <div className="teacher-actions">
          <TeacherActionButton className={teacherClassName} />
        </div>
        <div className="admin-actions">
          <CreateAnnouncementButton className={adminClassName} />
          <ReleaseEventButton className={adminClassName} />
          <AddFormButton className={adminClassName} />
          <ViewFormButton className={adminClassName} />
          <AttachExamButton className={adminClassName} division="Junior" />
          <AttachExamButton className={adminClassName} division="Senior" />
        </div>
      </div>
    </>
  );
}

export default EventActionGroup;
