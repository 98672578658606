import { Card } from 'react-bootstrap';
import FocusInput from '../forms/common/stateless/FocusInput';
import classes from './QuestionPreview.module.css';
import QuestionTagEditor from './QuestionTagEditor';
import PreviewMultiChoice from './QuestionTypes/PreviewMultiChoice';
import PreviewShortAnswer from './QuestionTypes/PreviewShortAnswer';

export function QuestionPreview({ question, changeQuestion }) {
  console.log('QuestionPreview', question);

  function changeBodyUi(newBody) {
    changeQuestion({ ...question, body: newBody });

    // Insert server & error handling logic
    // On fail -> setQuestions(oldQuestions);
  }

  return (
    <Card className={classes.wrapper} id={question.id}>
      <Card.Body className="pt-0">
        <QuestionTagEditor question={question} changeQuestion={changeQuestion} />
        <hr />
        <FocusInput content={question.body} onValueChange={changeBodyUi} rich />
        <hr />
        <AnswerInput question={question} changeQuestion={changeQuestion} />
      </Card.Body>
    </Card>
  );
}

function AnswerInput({ question, changeQuestion }) {
  const { type } = question;
  if (type === 'ShortAnswer') {
    return <PreviewShortAnswer question={question} changeQuestion={changeQuestion} />;
  }
  if (type === 'MultipleChoice') {
    return <PreviewMultiChoice question={question} changeQuestion={changeQuestion} />;
  }
  return type ? (
    <p>{`Question Type "${type}" Not Supported`}</p>
  ) : (
    <p>Question Missing Question Type</p>
  );
}
