import { useState } from 'react';
import { Card } from 'react-bootstrap';


export default function PlaceInBetween({ index, onDrop }) {
  const [covered, setCovered] = useState(false);

  const handleOnDragOver = (e) => {
    e.preventDefault();
    e.dataTransfer.dropEffect = 'move';
    setCovered(true);
  };

  const handleOnDragLeave = () => {
    setCovered(false);
  };

  return (
    <Card
      className={`col-lg-7 mx-auto ${covered ? 'border-primary' : 'border-0'}`}
      onDragOver={(e) => handleOnDragOver(e)}
      onDragLeave={() => handleOnDragLeave()}
      onDrop={(e) => onDrop(e, index, () => setCovered(false))}
    >
      <div className="text-primary my-2" />
    </Card>

  );
}
