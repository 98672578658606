export const mockQuestions = [
  {
    id: '67a485f2d8ec5d0700ee7c4f',
    body: '<h3>Multi choice answer</h3><p>Temporary question template to temporarily fill up the question for my react component\nTemporary question template to temporarily fill up the question for my react component\nTemporary question template to temporarily fill up the question for my react component\nTemporary question template to temporarily fill up the question for my react component\nTemporary question template to temporarily fill up the question for my react component\nTemporary question template to temporarily fill up the question for my react component\nTemporary question template to temporarily fill up the question for my react component</p>',
    topic: 'Calc',
    difficulty: 'Hard',
    division: 'Senior',
    imageUrl:
      'https://drive.google.com/file/d/1jLiXi7C4_kk-CvFpTC00IsdHlqJo7fwU/view',
    choices: {
      aa: '<h5>Rich Text - Multi choice answer </h5><p> Testing </p>',
      bb: '<h5>Second option</h5><p> Testing </p>',
      cc: '<h5>3rd potential answer</h5><p> Testing </p>',
      dd: '<h5>Rich Text</h5><p> Testing </p>',
    },
    type: 'MultipleChoice',
    rawCorrectIndex: 0,
    title: 'Multi Choice Question 1',
    usedFor: ['NZPMC25R1 - Junior', 'NZPMC25R1 - Senior', 'NZPMC25R2 - Junior', 'NZPMC25R2 - Senior'],
    status: 'Ready',
  },
  {
    id: '67a48694d8ec5d0700ee7c51',
    body: '<h3>Rich Text - Multi choice answer</h3><p>Temporary question template to temporarily fill up the question for my react component\nTemporary question template to temporarily fill up the question for my react component\nTemporary question template to temporarily fill up the question for my react component\nTemporary question template to temporarily fill up the question for my react component\nTemporary question template to temporarily fill up the question for my react component\nTemporary question template to temporarily fill up the question for my react component\nTemporary question template to temporarily fill up the question for my react component</p>',
    topic: 'Mechanics',
    division: 'Senior',
    choices: {
      aa: '<h5>Rich Text - Multi choice answer </h5><p> Testing </p>',
      bb: '<h5>Second option</h5><p> Testing </p>',
      cc: '<h5>3rd potential answer</h5><p> Testing </p>',
      dd: '<h5>Rich Text</h5><p> Testing </p>',
    },
    type: 'MultipleChoice',
    title: 'Multi Choice Question 2',
    rawCorrectIndex: 2,
    status: 'Draft',
  },
  {
    id: '67a486e1d8ec5d0700ee7c53',
    title: 'Short Answer Question 1',
    body: '<h3>Rich Text - Short answer question</h3><p>Temporary question template to temporarily fill up the question for my react component\nTemporary question template to temporarily fill up the question for my react component\nTemporary question template to temporarily fill up the question for my react component\nTemporary question template to temporarily fill up the question for my react component\nTemporary question template to temporarily fill up the question for my react component\nTemporary question template to temporarily fill up the question for my react component\nTemporary question template to temporarily fill up the question for my react component</p>',
    difficulty: 'Easy',
    imageUrl:
      'https://drive.google.com/file/d/1jLiXi7C4_kk-CvFpTC00IsdHlqJo7fwU/view',
    division: 'Senior',
    type: 'ShortAnswer',
    correctAns: 'This is the correct answer',
  },
  {
    id: '67a48701d8ec5d0700ee7c55',
    body: '<h3>Rich Text - Short answer question</h3><p>Temporary question template to temporarily fill up the question for my react component\nTemporary question template to temporarily fill up the question for my react component\nTemporary question template to temporarily fill up the question for my react component\nTemporary question template to temporarily fill up the question for my react component\nTemporary question template to temporarily fill up the question for my react component\nTemporary question template to temporarily fill up the question for my react component\nTemporary question template to temporarily fill up the question for my react component</p>',
    topics: ['Mechanics', 'Quantum', 'Calc'],
    tags: ['Hard'],
    division: 'Senior',
    type: 'ShortAnswer',
    title: 'Short Answer Question 2',
    correctAns: 'This is an example answer',
  },
  {
    id: '67a48701d8ec5d0700ee7c56',
    body: '<h3>Rich Text - Short answer question</h3><p>Temporary question template to temporarily fill up the question for my react component\nTemporary question template to temporarily fill up the question for my react component\nTemporary question template to temporarily fill up the question for my react component\nTemporary question template to temporarily fill up the question for my react component\nTemporary question template to temporarily fill up the question for my react component\nTemporary question template to temporarily fill up the question for my react component\nTemporary question template to temporarily fill up the question for my react component</p>',
    topics: ['Mechanics', 'Quantum', 'Calc'],
    tags: ['Hard'],
    division: 'Senior',
    title: 'Question with Missing Type',
  },
  {
    id: '67a48711d8ec5d0711ee7c56',
    body: '<h3>Rich Text - Short answer question</h3><p>Temporary question template to temporarily fill up the question for my react component\nTemporary question template to temporarily fill up the question for my react component\nTemporary question template to temporarily fill up the question for my react component\nTemporary question template to temporarily fill up the question for my react component\nTemporary question template to temporarily fill up the question for my react component\nTemporary question template to temporarily fill up the question for my react component\nTemporary question template to temporarily fill up the question for my react component</p>',
    topics: ['Mechanics', 'Quantum', 'Calc'],
    tags: ['Hard'],
    division: 'Senior',
    type: 'LongAnswer',
    title: 'Question with unsupported type',
  },
  {
    id: 'aaaaa5f2d8ec5d0700ee7c4f',
    body: '<h3>Multi choice answer</h3><p>Temporary question template to temporarily fill up the question for my react component\nTemporary question template to temporarily fill up the question for my react component\nTemporary question template to temporarily fill up the question for my react component\nTemporary question template to temporarily fill up the question for my react component\nTemporary question template to temporarily fill up the question for my react component\nTemporary question template to temporarily fill up the question for my react component\nTemporary question template to temporarily fill up the question for my react component</p>',
    topic: 'Calc',
    difficulty: 'Hard',
    division: 'Senior',
    imageUrl:
      'https://drive.google.com/file/d/1jLiXi7C4_kk-CvFpTC00IsdHlqJo7fwU/view',
    choices: {
      aa: '<h5>Rich Text - Multi choice answer </h5><p> Testing </p>',
      bb: '<h5>Second option</h5><p> Testing </p>',
      cc: '<h5>3rd potential answer</h5><p> Testing </p>',
      dd: '<h5>Rich Text</h5><p> Testing </p>',
    },
    type: 'MultipleChoice',
    rawCorrectIndex: 0,
    title: 'Multi Choice Question 1',
    usedFor: ['NZPMC25R1 - Junior', 'NZPMC25R1 - Senior', 'NZPMC25R2 - Junior', 'NZPMC25R2 - Senior'],
    status: 'Ready',
  },
  {
    id: '67a4aaaad8ec5d0700ee7c51',
    body: '<h3>Rich Text - Multi choice answer</h3><p>Temporary question template to temporarily fill up the question for my react component\nTemporary question template to temporarily fill up the question for my react component\nTemporary question template to temporarily fill up the question for my react component\nTemporary question template to temporarily fill up the question for my react component\nTemporary question template to temporarily fill up the question for my react component\nTemporary question template to temporarily fill up the question for my react component\nTemporary question template to temporarily fill up the question for my react component</p>',
    topic: 'Mechanics',
    division: 'Senior',
    choices: {
      aa: '<h5>Rich Text - Multi choice answer </h5><p> Testing </p>',
      bb: '<h5>Second option</h5><p> Testing </p>',
      cc: '<h5>3rd potential answer</h5><p> Testing </p>',
      dd: '<h5>Rich Text</h5><p> Testing </p>',
    },
    type: 'MultipleChoice',
    title: 'Multi Choice Question 2',
    rawCorrectIndex: 2,
    status: 'Draft',
  },
  {
    id: '67a486e1daacaa0700ee7c53',
    title: 'Short Answer Question 1',
    body: '<h3>Rich Text - Short answer question</h3><p>Temporary question template to temporarily fill up the question for my react component\nTemporary question template to temporarily fill up the question for my react component\nTemporary question template to temporarily fill up the question for my react component\nTemporary question template to temporarily fill up the question for my react component\nTemporary question template to temporarily fill up the question for my react component\nTemporary question template to temporarily fill up the question for my react component\nTemporary question template to temporarily fill up the question for my react component</p>',
    difficulty: 'Easy',
    imageUrl:
      'https://drive.google.com/file/d/1jLiXi7C4_kk-CvFpTC00IsdHlqJo7fwU/view',
    division: 'Senior',
    type: 'ShortAnswer',
    correctAns: 'This is the correct answer',
  },
  {
    id: '67a48701d8ec5d0700eeaaaa',
    body: '<h3>Rich Text - Short answer question</h3><p>Temporary question template to temporarily fill up the question for my react component\nTemporary question template to temporarily fill up the question for my react component\nTemporary question template to temporarily fill up the question for my react component\nTemporary question template to temporarily fill up the question for my react component\nTemporary question template to temporarily fill up the question for my react component\nTemporary question template to temporarily fill up the question for my react component\nTemporary question template to temporarily fill up the question for my react component</p>',
    topics: ['Mechanics', 'Quantum', 'Calc'],
    tags: ['Hard'],
    division: 'Senior',
    type: 'ShortAnswer',
    title: 'Short Answer Question 2',
    correctAns: 'This is an example answer',
  },
  {
    id: '67a48701d8ec5d0700aaaaaa',
    body: '<h3>Rich Text - Short answer question</h3><p>Temporary question template to temporarily fill up the question for my react component\nTemporary question template to temporarily fill up the question for my react component\nTemporary question template to temporarily fill up the question for my react component\nTemporary question template to temporarily fill up the question for my react component\nTemporary question template to temporarily fill up the question for my react component\nTemporary question template to temporarily fill up the question for my react component\nTemporary question template to temporarily fill up the question for my react component</p>',
    topics: ['Mechanics', 'Quantum', 'Calc'],
    tags: ['Hard'],
    division: 'Senior',
    title: 'Question with Missing Type',
  },
  {
    id: '67a48711d8ec5d0711ee7aaa',
    body: '<h3>Rich Text - Short answer question</h3><p>Temporary question template to temporarily fill up the question for my react component\nTemporary question template to temporarily fill up the question for my react component\nTemporary question template to temporarily fill up the question for my react component\nTemporary question template to temporarily fill up the question for my react component\nTemporary question template to temporarily fill up the question for my react component\nTemporary question template to temporarily fill up the question for my react component\nTemporary question template to temporarily fill up the question for my react component</p>',
    topics: ['Mechanics', 'Quantum', 'Calc'],
    tags: ['Hard'],
    division: 'Senior',
    type: 'LongAnswer',
    title: 'Question with unsupported type',
  },
];
