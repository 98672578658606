import {
  Card, Button, Col, Row,
} from 'react-bootstrap';
import { useState } from 'react';
import ExamStartModal from './ExamStartModal';

function ExamCard({ eventCode, exam }) {
  const [showModal, setShowModal] = useState(false);

  const startTime = new Date(exam.examStartTime);
  const endTime = new Date(exam.examEndTime);

  const today = new Date();
  const isToday = startTime.getDate() === today.getDate() && startTime.getMonth() === today.getMonth() && startTime.getFullYear() === today.getFullYear();

  let dateDisplay;
  if (!isToday && startTime > today) {
    // Exam is more than a day away, so display the date
    dateDisplay = `${startTime.toLocaleDateString([])}`;
  } else if (isToday && startTime > today) {
    // Exam is today but not started yet, so display the start time
    dateDisplay = `Opens at ${startTime.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}`;
  } else if (startTime < today && endTime > today) {
    // Exam is currently open, so display the closing time
    dateDisplay = `Closes at ${endTime.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}`;
  } else {
    // Exam is closed
    dateDisplay = 'Closed';
  }

  const openModal = () => {
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
  };

  let cardButton;
  if (startTime > new Date()) {
    cardButton = <Button className="w-100" variant="secondary" onClick={openModal}>Info</Button>;
  } else if (endTime < new Date()) {
    cardButton = <Button className="w-100" variant="outline-secondary" disabled>Ended</Button>;
  } else {
    cardButton = (
      <Button className="w-100" variant="success" onClick={openModal}>Open</Button>
    );
  }

  return (
    <Card className="shadow px-3 py-1 mb-3">
      <Row>
        <Col xs={8}>
          <Card.Body className="px-0">
            <Card.Title className="fs-6">{exam.eventName}</Card.Title>
            <Card.Subtitle className="fw-light">{dateDisplay}</Card.Subtitle>
          </Card.Body>
        </Col>
        <Col xs={4} className="d-flex align-items-center">
          {cardButton}
        </Col>
      </Row>
      <ExamStartModal show={showModal} handleClose={closeModal} eventCode={eventCode} exam={exam} />
    </Card>
  );
}

export default ExamCard;
