import { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Alert, Button, Col, Row } from 'react-bootstrap';
import {
  DatePickerInput, Section,
} from '../components/forms/common/FormComponents';
import { AuthContext } from '../context/AuthContextProvider';
import { SessionContext } from '../context/SessionContextProvider';
import { createEvent } from '../services/events';
import { ALERT_ERROR_MESSAGE } from '../utils/ResponseUtils';
import useWithLoading from '../hooks/useWithLoading';
import { PrettyForm } from '../components/forms/common/PrettyForm';
import TextBoxInput from '../components/forms/common/TextBoxInput';
import CheckBoxInput from '../components/forms/common/CheckBoxInput';
import NZTDatePicker from '../components/forms/common/NZTDatePicker';
import { PageContext } from '../context/PageContextProvider';
import Instructions from '../components/common/Instructions';

const pageMetadata = {
  titleLH: 'Create',
  titleRH: 'Event',
  body: 'You can create an event for a workshop or competition, and you can even create and attach a form by yourself!',
};


function CreateEventPage() {
  const {setPageMetadata} = useContext(PageContext)
  useEffect(() => setPageMetadata(pageMetadata), []);
  const { authRetrievedProfile } = useContext(AuthContext);
  const { session } = useContext(SessionContext);
  const history = useHistory();
  const [loading, withLoading] = useWithLoading();


  const onSubmit = () => withLoading(
    createEvent(authRetrievedProfile, session),
    () => {
      alert('Successfully created an event!');
      history.push(`/admin/createForm/${session.eventCode}`);
    },
    ALERT_ERROR_MESSAGE,
  );

  return (
    <PrettyForm
      submitText="Next: Create Form"
      onSubmit={onSubmit}
      cancelText="Cancel"
      onCancel={() => history.goBack()}
      loading={loading}
    >
      <Row>
        <Col xs={12} lg={8}>
          <Row>
            <Col><TextBoxInput label="Event Code" field="eventCode"/></Col>
            <Col><TextBoxInput label="Event Name" field="eventName"/></Col>
          </Row>
          <Row>
            <Col><NZTDatePicker label="Event Date" field="eventDate" required /></Col>
            <Col><NZTDatePicker label="Registration Due Date" field="registrationDueDate" required /></Col>
            <Col><TextBoxInput label="Default Event Price (in NZD)" field="defaultPrice" enforceNumeric/></Col>
          </Row>
          <Row>
            <Col><TextBoxInput label="Location" field="location" /></Col>
          </Row>
          <Row>
            <TextBoxInput label="Short Description" field="shortDescription"/>
          </Row>
          <Col>
            <CheckBoxInput label="Enable EQI Discount" field="isEQIDiscountEnabled" />
          </Col>
        </Col>
        <Col xs={12} lg={4}>
          <Alert variant="info">
            <Alert.Heading>Help</Alert.Heading>
            <Instructions header={"What is Event Code vs Event Name?"}>
              <b>Event Code</b> must be unique across all events. It must be a string with no white space.<br/><br/>
              <b>Event Name</b> can contain whitespace, but should be concise (ideally within 6 words)
            </Instructions>
            <Instructions header={"What is EQI?"}>
              EQI is a socio-economic index of each school. We have a discount rate defined for each EQI range, and this helps reduce the financial burden of registration across the board.
            </Instructions>
            <Instructions header={"After creating event..."}>
              <ol>
                <li><b>Release event</b>: Creating event doesn't mean releasing event. </li>
                <li><b>Add Event Access Policy</b>: Event is by default public</li>
              </ol>
              Note that year 14+ are not invited to events
            </Instructions>
          </Alert>
        </Col>
      </Row>
    </PrettyForm>
  );
}

export default CreateEventPage
