import { Table } from 'react-bootstrap';


export default function QuestionTabler({ questions, selectedQuestionId, onSelectedQuestionChange }) {
  return (
    <Table bordered hover responsive className="mt-3">
      <thead>
        <tr>
          <th>Title</th>
          <th>Difficulty</th>
          <th>Topic</th>
          <th>Division</th>
        </tr>
      </thead>
      <tbody>
        {questions.map((question) => (
            <tr className={selectedQuestionId === question.id && `bg-primary-subtle`} key={question.id} onClick={()=>{
                document.getElementById("questionTable").scrollIntoView({behavior: "smooth"});
                onSelectedQuestionChange(question.id)
            }}>
                <td>{question.title}</td>
                <td>{question.difficulty}</td>
                <td>{question.topic}</td>
                <td>{question.division}</td>
            </tr>
        ))}
      </tbody>
    </Table>
  );
}
