import { Form } from 'react-bootstrap';

// PrettyFormGroup without my-3: Hajin says a little duplication is okay
export default function PrettyFormGroupV2({
  label, children, required = false, className = '',
}) {
  return (
    <Form.Group className={`${className} flex-grow-1`}>
      {label && (
        <Form.Label>
          {label}
          {required && <span style={{ color: 'red' }}>{' *'}</span>}
        </Form.Label>
      )}
      {children}
    </Form.Group>
  );
}
