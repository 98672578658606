import React, {
  createContext, useContext, useEffect, useState,
} from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useHistory, useParams } from 'react-router-dom';
import createPersistedState from 'use-persisted-state';
import { toast } from 'react-toastify';
import ExamQuestion from '../components/exampage/ExamQuestion';
import QuestionNavScroll from '../components/exampage/QuestionNavScroll';
import classes from '../components/exampage/ExamPage.module.css';
import { AuthContext } from '../context/AuthContextProvider';
import { PageContext } from '../context/PageContextProvider';
import SubmitAttemptButton from '../components/exampage/SubmitAttemptButton';
import TimerDisplay from '../components/exampage/TimerDisplay';
import { examIsLive, useInterval } from '../utils/ExamUtils';
import { getExamForStudent, submitAttempt } from '../services/exams';
import { ALERT_ERROR_MESSAGE } from '../utils/ResponseUtils';
import useWithLoading from '../hooks/useWithLoading';
import Loading from '../components/common/Loading';
import axios from "axios";

// examResponses stores exam responses across multiple events:
// ie., examResponses[eventCode] stores responses for {eventCode}
const usePersistedStoreForAllExamResponses = createPersistedState('allExamResponses');
const usePersistedStoreForTimestamp = createPersistedState('examTimestamp');

/**
 * ExamContext exposes current exam & responses (persisted)
 * as well as hooks to submit exam
 */
export const ExamContext = createContext({
  exam: {
    examId: '',
    examStartTime: null,
    examEndTime: null,
    division: null,
    // questionIds: [] (should not be visible to students, but exists in backend)
    questions: [],
  },

  timestamp: null,

  userChoices: {},

  // tracks all the question ids that user had flagged
  flags: [],
  toggleFlag: (qid) => {},
  submitUserChoices: () => {},
  // for reloading purpose. Will use the eventCode that's already loaded in the context
  reload: () => {},
  // for resetting the exam after exam submission.
  resetState: () => {},
});


export default function ExamPage() {
  const { authRetrievedProfile } = useContext(AuthContext);
  const { setPageMetadata } = useContext(PageContext);
  const { eventCode } = useParams();

  const [exam, setExam] = useState({});
  const [flags, setFlags] = useState([]);
  const [timestamp, setTimestamp] = usePersistedStoreForTimestamp(null);
  const [userChoices, setUserChoices] = useState(undefined);
  const [userChoicesForAllExams, setUserChoicesForAllExams] = usePersistedStoreForAllExamResponses();

  const [isSubmitted, setIsSubmitted] = useState(false);

  const [loading, withLoading] = useWithLoading();
  const history = useHistory();

  useEffect(() => {
    if (eventCode == null) { history.goBack(); toast.error('Event code was null'); }
    setPageMetadata(undefined);
  }, [authRetrievedProfile]);

  useEffect(() => {
    reload();
  }, []);

  useEffect(() => {
    const copy = { ...userChoicesForAllExams };
    copy[eventCode] = userChoices;
    setUserChoicesForAllExams(copy);
  }, [userChoices]);

  useEffect(() => {
    const handleBeforeUnload = (e) => {
      if (isSubmitted) return;
      e.preventDefault();
      e.returnValue = '';
    };
    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [isSubmitted]);

  const [wait, setWait] = useState(1000);

  useInterval(() => {
    if (timestamp) {
      const newDate = (new Date()).getTime();
      const nextStamp = timestamp + 1000 * 5 * 60;
      if (nextStamp <= newDate) {
        submitUserChoices()
          .then(() => setTimestamp(newDate))
          .catch(() => console.error('Failed to save responses to server'));
      } else {
        setWait(nextStamp - newDate);
      }
    }
  }, wait);

  const reload = () => {
    withLoading(
      getExamForStudent(authRetrievedProfile, eventCode),
      (exam) => {
        if (examIsLive(exam?.data)) {
          setExam(exam?.data);
          setUserChoices(userChoicesForAllExams[eventCode] || {});
          setTimestamp((new Date()).getTime());
        } else {
          history.push('/');
          toast.error('Exam is not Live');
        }
      },
      ALERT_ERROR_MESSAGE,
    );
  };

  const toggleFlag = (questionId) => {
    if (flags.includes(questionId)) {
      const filtered = flags.filter((id) => id !== questionId);
      setFlags(filtered);
    } else {
      const newFlagSet = [...flags, questionId];
      setFlags(newFlagSet);
    }
  };

  const handleAnswer = (questionId, answer) => {
    const copy = { ...userChoices };
    copy[questionId] = answer;
    setUserChoices(copy);
  };

  const submitUserChoices = async () => {
    const examSubmission = {
      id: {
        studentEmail: authRetrievedProfile.email,
        eventCode,
      },
      userChoices,
    };

    try {
      await submitAttempt(authRetrievedProfile, examSubmission);
    } catch (err) {
      // toast.error('dev-purpose (and should be removed in prod): Failed to Submit');

      const errorLog = {
        studentEmail: authRetrievedProfile.email,
        eventCode: eventCode,
        examSubmission: {
          ...examSubmission,
          authCode: authRetrievedProfile.code,
        }
      }

      await axios.post('https://nzpmc-backup-service-63b01f05b710.herokuapp.com/examSubmissionsBackup', errorLog)
      
    } finally {
      setIsSubmitted(true);
    }
  };

  const submitAndEndExam = async () => {
    history.push(`/examEndScreen/${eventCode}`);
    await submitUserChoices();
  };

  return (
    <Loading loading={loading}>
      <div style={{ marginTop: '50px' }}>
        <ExamContext.Provider value={{
          exam, userChoices, flags, handleAnswer, toggleFlag, submitUserChoices, reload,
        }}
        >
          <Container>
            <Row>
              <Col lg={8}>
                <div className={classes.move}>
                  <QuestionNavScroll questions={exam?.questions} className={classes.move} />
                </div>

                {userChoices && exam?.questions?.map((question, index) => (
                  <ExamQuestion key={question.id} question={question} index={index} />
                ))}

                <div className={classes.move}>
                  <SubmitAttemptButton />
                </div>
              </Col>
              <Col>
                <div className={classes.affix}>
                  <QuestionNavScroll questions={exam?.questions} />
                  <TimerDisplay
                    examEndTime={new Date(exam.examEndTime)}
                    navigateAndSubmitCallback={submitAndEndExam}
                  />
                  <SubmitAttemptButton />
                </div>
              </Col>
            </Row>
          </Container>
        </ExamContext.Provider>
      </div>
    </Loading>
  );
}
