import Form from 'react-bootstrap/Form';
import { useState } from 'react';

// eslint-disable-next-line import/no-cycle
import { PrettyFormGroup } from '../FormComponents';

export default function FileInputV2({ label, value, onValueChange, required = false, sizeMB = 15 }) {
  const [error, setError] = useState("");

  const handleFileChange = (e) => {
    const file = e.target.files[0];

    if (file) {
      const maxSize = sizeMB * 1024 * 1024;
      if (file.size > maxSize) {
        setError(`File size exceeds ${sizeMB}MB limit.`);
        onValueChange(null);
        e.target.value = null
      } else {
        setError("");
        onValueChange(file);
      }
    }
  };

  return (
    <PrettyFormGroup label={label} required={required}>
      {value && <div className={"border p-3 text-center mb-3"}>
        <img src={value} className={"w-100"} />
        <p className={"text-muted small"}>Current image</p>
      </div>}
      <Form.Control type="file" onChange={handleFileChange} isInvalid={error} />
      {error && <Form.Control.Feedback type="invalid">{error}</Form.Control.Feedback>}
    </PrettyFormGroup>
  );
};