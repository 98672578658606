import { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { Link } from 'react-router-dom/cjs/react-router-dom';
import { AuthContext } from '../../../../context/AuthContextProvider';
import { EventContext } from '../../../../pages/EventPage';
import { ADMIN_ROLE, getRole } from '../../../../utils/roleComputation';

export default function AttachJuniorExamButton({ className, division }) {
  const { authRetrievedProfile: auth } = useContext(AuthContext);
  const { eventCode, juniorExamCode, seniorExamCode } = useContext(EventContext);

  if (getRole(auth) !== ADMIN_ROLE) return null;

  const examCode = division === 'Senior' ? seniorExamCode : juniorExamCode;

  if (!examCode) {
    return (
      <Link className={className} to={`/admin/createExam/${eventCode}/${division}`}>
        {` Create Exam (${division})`}
      </Link>
    );
  }
  return (
    <div
      className="p-2 rounded-3 my-3"
      style={{
        border: '1px solid darkgray',
        backgroundColor: 'antiquewhite',
      }}
    >
      <small>
        {` ${division} Exam ID: ${examCode}`}
      </small>
      <Link className={className} to={`/admin/createQuestion/${examCode}`}>
        {' Add question'}
      </Link>
      <Link className={className} to={`/admin/previewExam/${examCode}`}>
        {' View exam'}
      </Link>
    </div>
  );
}
