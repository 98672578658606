import axios from 'axios';

export const createQuestion = (auth, question, examCode, imageFile) => async () => {
  const formData = new FormData();

  formData.append('questionJson', JSON.stringify(question));

  if (imageFile) {
    formData.append('file', imageFile);
  }

  const url = examCode === undefined
    ? `/questions?email=${auth.email}&code=${auth.code}`
    : `/questions/${examCode}?email=${auth.email}&code=${auth.code}`;

  return await axios.post(url, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
};
