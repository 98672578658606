import { useContext, useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { AuthContext } from '../../context/AuthContextProvider';
import useWithLoading from '../../hooks/useWithLoading';
import { getAllAnnouncements } from '../../services/announcement';
import { getEvents } from '../../services/events';
import { ALERT_ERROR_MESSAGE } from '../../utils/ResponseUtils';
import AnnouncementList from '../announcement/AnnouncementList';
import Loading from '../common/Loading';
import './EventView.css';
import EventCard from './EventCard';
import DashboardLinks from './DashboardLinks';
import ExamList from './ExamList';

function Dashboard() {
  const [events, setEvents] = useState();
  const { authRetrievedProfile: auth } = useContext(AuthContext);
  const [loading, withLoading] = useWithLoading();

  useEffect(() => {
    withLoading(getEvents(auth), (res) => setEvents(res?.data), ALERT_ERROR_MESSAGE);
  }, []);

  const [loading2, withLoading2] = useWithLoading();
  const [announcements, setAnnouncements] = useState();

  const { authRetrievedProfile } = useContext(AuthContext);

  useEffect(() => {
    withLoading2(
      () => getAllAnnouncements(authRetrievedProfile?.email, authRetrievedProfile?.code),
      (res) => setAnnouncements(res?.data),
      ALERT_ERROR_MESSAGE,
    );
  }, []);

  const eventsThisYear = events?.filter((event) => new Date(event?.eventDate).getFullYear() === new Date().getFullYear());
  const upcomingEvents = eventsThisYear?.filter((event) => new Date(event?.eventDate) >= new Date());
  const pastEvents = eventsThisYear?.filter((event) => new Date(event?.eventDate) < new Date());

  return (
    <>
      <Loading loading={loading}>

        <Row>
          <Col>
            <ExamList />
          </Col>
          <Col>
            <p className="fw-bold text-muted">Go to</p>
            <DashboardLinks />
            <br />
          </Col>
        </Row>
        <Row className="mb-3">
          <Col md={12} xl={6} className="mb-3">
            <p className="fw-bold text-muted">
              Recent Announcements
              {'  '}
              <Link to="/announcements">
                <i className="bi bi-box-arrow-up-right" />
              </Link>
            </p>
            <Loading loading={loading2} message="Retrieving announcements">
              <AnnouncementList announcements={announcements?.slice(0, 5)} />
            </Loading>
          </Col>
          <Col md={12} xl={6}>
            <EventListSummary
              title="Upcoming Events"
              events={upcomingEvents}
              eventNotAvailableText="None yet - keep an eye out for announcements!"
              showLinkToAllEvents
            />
            <EventListSummary
                title="Recently Ended Events"
                events={pastEvents}
                eventNotAvailableText="No events happened this year"
            />
          </Col>
        </Row>
        <Row>

        </Row>
      </Loading>
    </>
  );
}

function EventListSummary({
  title, events, showLinkToAllEvents, eventNotAvailableText,
}) {
  function LinkToAllEvents() {
    return showLinkToAllEvents ? (
      <Link to="/events">
        <i className="bi bi-box-arrow-up-right ms-1" />
      </Link>
    ) : (
      <></>
    );
  }

  const nothingToDisplay = events?.length === 0;

  return (
    <>
      <p className="fw-bold text-muted">
        {title}
        <LinkToAllEvents />
      </p>
      {nothingToDisplay && (
        <p className="fs-5 text-warning-emphasis fw-bolder">
          <i className="bi bi-check2" />
          {' '}
          {eventNotAvailableText}
        </p>
      )}
      <Row className="d-flex">
        {events?.map((event, index) => (
          <Col lg={6}>
            <EventCard event={event} index={index + 1} />
          </Col>
        ))}
      </Row>
    </>
  );
}

export default Dashboard;
