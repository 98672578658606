import { useContext, useEffect, useState } from 'react';
import {
  Breadcrumb, Button, Col, Row,
} from 'react-bootstrap';
import { useHistory, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import Loading from '../components/common/Loading';
import CompactQuestion from '../components/examPreviewPage/CompactQuestion';
import PlaceInBetween from '../components/examPreviewPage/PlaceInBetween';
import { mockQuestions } from '../components/exampage/examMockData';
import { AuthContext } from '../context/AuthContextProvider';
import { PageContext } from '../context/PageContextProvider';
import useWithLoading from '../hooks/useWithLoading';
import { getExamForAdmin, updateExamQuestionOrder } from '../services/exams';
import { ALERT_ERROR_MESSAGE } from '../utils/ResponseUtils';

export function PreviewExamPage() {
  const { setPageMetadata } = useContext(PageContext);
  const { authRetrievedProfile } = useContext(AuthContext);
  const [questions, setQuestions] = useState(mockQuestions);
  const [loading, withLoading] = useWithLoading();
  const { examCode } = useParams();
  const history = useHistory();

  useEffect(() => {
    withLoading(
      getExamForAdmin(authRetrievedProfile, examCode),
      (response) => setQuestions(response.data.questions),
      ALERT_ERROR_MESSAGE,
    );
  }, [authRetrievedProfile]);

  useEffect(() => {
    setPageMetadata({
      titleLH: 'Exam',
      titleRH: 'Preview',
      body: `You can add questions and edit questions. Currently displaying exam of code: ${examCode}.`,
    });
  }, []);

  const handleOnDropSwitch = (e, index, setCovered) => {
    const draggedId = e.dataTransfer.getData('draggedQuestion');
    const indexDragged = questions.findIndex((question) => question.id === draggedId);
    setCovered(false);

    if (indexDragged === index) return;

    const newQuestions = [...questions];
    [newQuestions[indexDragged], newQuestions[index]] = [newQuestions[index], newQuestions[indexDragged]];
    setQuestions(newQuestions);
  };

  const filterOut = (questionId, questions) => questions.filter((question) => question.id !== questionId);

  const handleOnDropBetween = (e, index, setCovered) => {
    const draggedId = e.dataTransfer.getData('draggedQuestion');
    const indexDragged = questions.findIndex((question) => question.id === draggedId);
    setCovered(false);

    if (indexDragged === index || indexDragged + 1 === index) return;

    const firstSlice = questions.slice(0, index);
    const secondSlice = questions.slice(index);
    const newQuestions = [
      ...filterOut(draggedId, firstSlice),
      questions[indexDragged],
      ...filterOut(draggedId, secondSlice),
    ];
    setQuestions(newQuestions);
  };

  const handleSave = () => {
    withLoading(
      updateExamQuestionOrder(authRetrievedProfile, examCode, questions.map((question) => question.id)),
      (res) => toast.success('Saved the question order successfully! (question changes not saved)'),
      ALERT_ERROR_MESSAGE,
    );
  };

  function changeQuestion(newQuestion) {
    const newQuestions = questions.map((question) => (question.id === newQuestion.id ? newQuestion : question));
    setQuestions(newQuestions);
  }

  return (
    <Loading loading={loading}>
      <Breadcrumb>
        <Breadcrumb.Item onClick={() => history.push('/')}>Home</Breadcrumb.Item>
        <Breadcrumb.Item active>Exam Preview</Breadcrumb.Item>
      </Breadcrumb>
      <Row>
        <Col xs={8}>
          <Button
            className="mt-3 w-100"
            variant="outline-primary"
            onClick={() => history.push(`/admin/createQuestion/${examCode}`)}
          >
            Add Question
          </Button>
          {questions.map((question, index) => (
            <div key={question.id}>
              <PlaceInBetween index={index} onDrop={handleOnDropBetween} />
              <CompactQuestion
                question={question}
                index={index}
                onDrop={handleOnDropSwitch}
                changeQuestion={changeQuestion}
              />
            </div>
          ))}
        </Col>
        <Col xs={4}>
          <div className="border p-3">
            <p className="fw-bold">Actions</p>
            <Button
              className="w-100 mb-2"
              variant="success"
              onClick={() => handleSave()}
            >
              Save
            </Button>
            <Button
              variant="outline-secondary"
              className="w-100"
              onClick={() => history.goBack()}
            >
              Return
            </Button>
          </div>
        </Col>
        <div>
          <PlaceInBetween index={questions.length} onDrop={handleOnDropBetween} />
        </div>
      </Row>
    </Loading>
  );
}
