import { useContext, useEffect, useState } from 'react';
import {Button, Card, Form, InputGroup, Stack} from 'react-bootstrap';
import RichTextInputV2 from "../../common/stateless/RichTextInputV2";
import styles from "../../../examPreviewPage/QuestionTypes/PreviewMultiChoice.module.css";
import FocusInput from "../../common/stateless/FocusInput";

/**
 * Used for creating multiple choice questions
 */
function MultiChoiceBuilderV2({ label, choices, correctAnswerIndex, onChoiceArrayChange, onCorrectChoiceIndexChange, choiceArraySize }) {
  const choiceComponents = [];

  console.log('lol', choices, correctAnswerIndex);

  if(choices !== undefined && correctAnswerIndex !== undefined) {
    for(let i = 0; i < choices.length; i++) {
      choiceComponents.push(
          <InputGroup key={i} className="my-3">
            <InputGroup.Radio name={"same radio"} defaultChecked={correctAnswerIndex === i} onChange={() => onCorrectChoiceIndexChange(i)} required />
            <RichTextInputV2 content={choices[i]} onValueChange={choiceText =>  onChoiceArrayChange(i, choiceText)} required />
          </InputGroup>,
      );
    }
  } else {
    for (let choiceIndex = 0; choiceIndex < choiceArraySize; choiceIndex++) {
      choiceComponents.push(
        <InputGroup key={choiceIndex} className="my-3">
          <InputGroup.Radio name={"same radio"} onChange={() => onCorrectChoiceIndexChange(choiceIndex)} required />
          <RichTextInputV2 onValueChange={choiceText => onChoiceArrayChange(choiceIndex, choiceText)} required />
        </InputGroup>,
      );
    }
  }


  return <div>
    <p>{label}</p>
    {choiceComponents}
  </div>;
}


export default MultiChoiceBuilderV2;
