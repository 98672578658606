import { Form } from 'react-bootstrap';
import FocusInput from '../../forms/common/stateless/FocusInput';

export default function PreviewShortAnswer({ question, changeQuestion }) {
  function changeAnswerUi(newAnswer) {
    changeQuestion({ ...question, correctAns: newAnswer });

    // Insert server & error handling logic
    // On fail -> setQuestions(oldQuestions);
  }

  return (
    <Form>
      <Form.Group>
        <Form.Label>Correct answer:</Form.Label>
        <FocusInput
          value={question.correctAns}
          onChange={(e) => changeAnswerUi(e.target.value)}
        />
      </Form.Group>
    </Form>
  );
}
